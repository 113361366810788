import React, { useState, useEffect } from 'react'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Design from './pages/Design';
import Vr from './pages/Vr';
import Projects from './pages/Projects';
import WebAppsPage from './pages/WebApps';
import ServicesPage from './pages/Services';
import About from './pages/About';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Divider, Box } from '@mui/material';



import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import SpeedDial from './components/SpeedDial';
import SvgAnimated from './components/SvgAnimated';

// Google Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-185916796-3"; 
ReactGA.initialize(TRACKING_ID);



const theme = createTheme({
  palette: {
    primary: {
      main: '#ebebeb',
      light: '#f9f9f9'
    },
    dark: {
      main: '#818181',
    },
    divider: "#818181"
  },
  status: {
    danger: orange[500],
  },
  typography: {
    fontFamily: [
      'Noto Sans Ethiopic',
      'sans-serif',
    ].join(','),
  }
});

// const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
//   color: theme.status.danger,
//   '&.Mui-checked': {
//     color: theme.status.danger,
//   },
// }));



function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [ready, setReady] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setReady(true);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar isOpen={isOpen} toggle={toggle} />
        <Divider />
        <Box height={150} position="relative" sx={{ backgroundColor: 'primary.light' }} />
        <Divider />
        {!ready ?
          <SvgAnimated />
          : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/design/*" element={<Design />} />
          <Route path="/vr/*" element={<Vr />} />
          <Route path="/webapp/*" element={<WebAppsPage />} />
          <Route path="/other-services/*" element={<ServicesPage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/svg" element={<SvgAnimated />} />
        </Routes>
      </BrowserRouter>
      <SpeedDial />
    </ThemeProvider>
  );
}

export default App;