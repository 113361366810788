import React, {useState} from 'react'
import HeroBanner from '../components/HeroBanner'
import Navbar from '../components/Navbar'
import ProjectsCards from '../components/Projects'
import Quotation from '../components/QuotationComp'
import Sidebar from '../components/Sidebar'
import DesignBanner from '../images/DesignBanner.png'



const Projects = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
            <HeroBanner backImg={DesignBanner} text={"All Projects"}/>
            <ProjectsCards/>
            <Quotation/>
        </div>
    )
}

export default Projects
