import { useState } from 'react';
import { Box, Divider } from '@mui/material';
import Sending from './ElementSending';
import Form from './Form';

import Sent from './ElementSent';



const Quotation = () => {

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(null);






    return (
        <>
            <Divider></Divider>
            <Box positoon="relative">
                {sent ? <Sent /> : null}
                {sending ? (<Sending />) : null}
                <Form
                    setSending={setSending}
                    setSent={setSent}
                    setError={setError}
                    error={error}
                />
            </Box>
        </>
    )
}

export default Quotation