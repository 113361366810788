import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';

const actions = [
    { icon: <EmailIcon />, name: 'Email' },
    { icon: <InstagramIcon />, name: 'Instagram' },
    { icon: <FacebookIcon />, name: 'Facebook' }
];

export default function BasicSpeedDial() {
    return (
        <Box sx={{
             position: 'fixed', bottom: 0, right: 0, height: 320, transform: 'translateZ(0px)', flexGrow: 1, backgroundColor: 'black !important', color: 'black'
              }}
              color='secondary'
              >
            <SpeedDial
            color='secondary'
                ariaLabel="SpeedDial basic example"
                sx={{ position: 'absolute', bottom: 16, right: 16, color: 'black !important' }}
                icon={<SpeedDialIcon             color='secondary'
                />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}