import { Box, Typography } from '@mui/material'
import { Poly, PolyL, AnimatePol, AnimateGroup } from './SvgElements'

const SvgAnimated = () => {
    return (
        <Box width="100vw" height="100vh" position="fixed" top="0" left="0" display="flex" justifyContent={"center"} alignItems="center" flexDirection={"column"} backgroundColor="primary.light" zIndex={999}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={80} height={200}
                viewBox="0 0 436.23 509.28" >
                <g id="Static">
                    <Poly class="st0" points="3.71,372.88 3.71,127.71 219.96,250.3" />
                    <PolyL class="st0" points="219.96,509.28 219.96,250.3 432.55,127.71 432.55,382.72 	" />
                </g>
                <AnimateGroup>
                    <AnimatePol id="Rot" className="st1" points="3.71,125.71 219.96,4.36 432.55,125.71 " x="50px" y="50"/>
                </AnimateGroup>
            </svg>
            <Typography variant='caption' component={"h5"}>Loaading ...</Typography>
        </Box>
    )
}

export default SvgAnimated