import React from 'react'
import { Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const FeaturedProject = (props) => {
    const { title, description, img, path } = props;

    const navigate = useNavigate();
    return (
        <Grid md={3} paddingX={1} sx={{ cursor: 'pointer' }}>
            <Card sx={{ maxWidth: 345, backgroundColor: 'rgba(0,0,0,0)' }} onClick={() => navigate(path)}>
                <CardMedia
                    component="img"
                    height="300"
                    image={img}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default FeaturedProject