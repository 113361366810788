import { useForm } from "react-hook-form";
import { Button, Grid, TextField, FormControl } from '@mui/material';


const FormQuote = (props) => {
    const { register, formState: { errors }, handleSubmit } = useForm({
        criteriaMode: 'all',
        mode: "onBlur",
        reValidateMode: "onChange"
    });
    return (
        <FormControl onSubmit={handleSubmit(props.handleSubmit1)} >
            <Grid container>
                <Grid md={6} padding={2}>
                    <TextField
                        {...register("name", {
                            required: {
                                value: true,
                                message: "this is required"
                            },
                            maxLength: {
                                value: 12,
                                message: "Name is too long"
                            },
                            pattern: {
                                value: /[A-Za-z]/,
                                message: "Numbers or symbols are not allowed"
                            }
                        })}
                        error={errors?.name}
                        helperText={errors?.name?.message}
                        type={"text"}
                        fullWidth size="small" id="outlined-basic" label="Name" variant="outlined" required
                    />
                </Grid>
                <Grid md={6} padding={2}>
                    <TextField
                        {...register("email", {
                            required: {
                                value: true,
                                message: "this is required"
                            },
                            pattern: {
                                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                message: "Email is not correct"
                            }
                        })}
                        error={errors?.email}
                        helperText={errors?.email?.message}
                        type="email"
                        size="small" fullWidth id="outlined-basic" label="Email" variant="outlined" required />
                </Grid>
                <Grid md={6} padding={2}>
                    <TextField
                        {...register("type", {
                            required: {
                                value: true,
                                message: "this is required"
                            }
                        })}
                        error={errors?.type}
                        helperText={errors?.type?.message}
                        type={"text"}
                        size="small" fullWidth id="outlined-basic" label="Type of service" variant="outlined" required />
                </Grid>
                <Grid md={6} padding={2}>
                    <TextField
                        {...register("budget", {
                            required: {
                                value: true,
                                message: "this is required"
                            },
                            maxLength: {
                                value: 12,
                                message: "Budget is too large"
                            },
                            pattern: {
                                value: /^\d+$/,
                                message: "Numbers only are allowed"
                            }
                        })}
                        error={errors?.budget}
                        helperText={errors?.budget?.message}
                        type={"number"}
                        size="small" fullWidth id="outlined-basic" label="Budget" variant="outlined" required />
                </Grid>
                <Grid md={12} padding={2}>
                    <TextField
                        {...register("message", {
                            required: {
                                value: true,
                                message: "this is required"
                            }
                        })}
                        error={errors?.message}
                        helperText={errors?.message?.message}
                        type={"text"}
                        size="small" fullWidth id="outlined-basic" label="Additional Details" variant="outlined" required />
                </Grid>
                <Grid md={12} padding={2} display="flex" justifyContent="center">
                    <Button variant={'contained'} size="small" color="dark"
                        sx={{ borderRadius: 0, padding: '10px 20px', marginX: 2 }}
                        onClick={handleSubmit(props.handleSubmit1)}
                        type="submit"
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
}

export default FormQuote