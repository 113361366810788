import styled, { keyframes } from 'styled-components'


// const circularAnimation = keyframes`
//     0% { opacity: 0.6; transform: rotate(1deg); transform: translateX(42px);}
//     25% { opacity: 1; transform: rotate(90deg); }
//     50% { opacity: 0.3; transform: rotate(180deg);}
//     75% { opacity: 0.6; transform: rotate(270deg);}
//     100% { opacity: 0.6; transform: rotate(359deg);}
// `

// const circularAnimation = keyframes`
//     0% { transform: translateY(0px) translateX(0px) rotate(1deg);}
//     50% { transform: translateY(250px) translateX(440px) rotate(180deg);}
//     100% { transform: translateY(0px) translateX(0px) rotate(356deg);}
// `

const circularAnimation = keyframes`
    0% { transform: scale(1) rotate(1deg);}
    25% {transform: scale(0.8) rotate(90deg)}
    50% { transform: scale(1) rotate(180deg);}
    75% {transform: scale(0.8) rotate(270deg)}
    100% { transform: scale(1) rotate(356deg);}
`

export const Poly = styled.polygon`
    fill:none;
    stroke:#000000;
    stroke-width:6;
    stroke-miterlimit:10;
`

export const PolyL = styled.polygon`
    fill:none;
    stroke:#000000;
    stroke-width:6;
    stroke-miterlimit:10;
`

export const AnimatePol = styled.polygon`
    fill:none;
    stroke:#000000;
    stroke-width:6;
    stroke-miterlimit:10;
    transform-origin: 220px 20px;
    animation: 2s infinite ease-in-out ${circularAnimation};
    animation-delay: 2s;
    margin-bottom: 20px;
    margin: 100px;
    position: fixed;
    top: 0;
    left: 0;
    x: 10px;
`

export const AnimateGroup = styled.g`
bottom: 20px;
margin-bottom: 20px;
margin: 100px;
position: fixed;
top: 0;
left: 0;
x: 100px;
y: 50;
`


