import React from 'react'
import { Box, CircularProgress } from '@mui/material';

const Sending = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" sx={{opacity: 0.8}} paddingY={10} position="absolute" width="100%" height="100%" zIndex={2}>
            <CircularProgress color='dark'/>
        </Box>
    )
}

export default Sending