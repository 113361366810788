
import ProjectCard from '../components/ProjectCards'
import Quotation from '../components/QuotationComp'
import ServiceImg1 from '../images/Grid-Design.png'
import { Grid } from '@mui/material'


const ServicesPage = () => {
    return (
        <>
            <Grid container padding={3}>
                <ProjectCard title="Drafting" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="BIM" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="VR Models" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Render" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
            </Grid>
            <Quotation />
        </>
    )
}

export default ServicesPage
