import { useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import FormQuote from './FormQuote';
import FormContact from './FormContact';
import ReCAPTCHA from 'react-google-recaptcha';

import SendQuote from './SendEmailJs';

const siteKey = "6Le8L8MiAAAAAK0gcP53q4M5Ucb_1Il7HWq6DqZ4";

const Form = (props) => {
    const recaptchaRef = useRef()
    const {
        setSending,
        setSent,
        setError
    } = props


    const [quotActive, setQuotActive] = useState(true);

    const handleSubmit1 = async (e) => {
        const token = await recaptchaRef.current.getValue(); //executeAsync executes the captcha and awaits the result if user input is required. 
        const data = { ...e, 'g-recaptcha-response': token }
        token && submitInput1(data); //when the token is ready, the actual submit function is called
    }

    const submitInput1 = async (data) => {
        setSending(true);
        const result = await SendQuote(data);
        if (result.status === 200) {
            setSending(false)
            setSent(true)
        } else { setError(result) }
    }

    const handleSubmit2 = async (e) => {
        const token = await recaptchaRef.current.getValue(); //executeAsync executes the captcha and awaits the result if user input is required. 
        const data = { ...e, 'g-recaptcha-response': token }
        token && submitInput2(data); //when the token is ready, the actual submit function is called
    }

    const submitInput2 = async (data) => {
        setSending(true);
        const result = await SendQuote(data);
        if (result.status === 200) {
            setSending(false)
            setSent(true)
        } else { setError(result) }
    }



    return (
        <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={10}>
            <Box maxWidth="850px">
                <Box display="flex" justifyContent="center" alignItems="center" paddingY={3}>
                    <Button variant={quotActive ? 'contained' : 'outlined'} size="small" color="dark"
                        sx={{ borderRadius: 0, padding: '10px 20px', marginX: 2 }}
                        onClick={() => setQuotActive(true)}>
                        Request Quotation
                    </Button>
                    <Button variant={!quotActive ? 'contained' : 'outlined'} size="small" color="dark"
                        sx={{ borderRadius: 0, padding: '10px 20px', marginX: 2 }}
                        onClick={() => setQuotActive(false)}>
                        Contact Us
                    </Button>
                </Box>

                <Typography variant="subtitle1" component="h5" color="dark.main" align="center">We are excited to learn about your new project</Typography>
                <Typography variant="subtitle1" component="h5" color="dark.main" align="center">Ready to get started?</Typography>

                {quotActive ? (
                    // First Form
                    <FormQuote handleSubmit1={handleSubmit1} />
                ) : (
                    // Second Form
                    <FormContact handleSubmit2={handleSubmit2} />
                )}
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={siteKey} />
            </Box>
        </Box>)
}

export default Form