
import ProjectCard from '../components/ProjectCards'
import Quotation from '../components/QuotationComp'
import ServiceImg1 from '../images/Grid-Design.png'
import { Grid, Typography } from '@mui/material'


const VrPage = () => {
    return (
        <>

            <Grid container padding={3}>
                <Typography variant="h2" component="h2">Coming Soon</Typography>
                {/* <ProjectCard title="Garden's Ave." description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Garden's Ave." description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Garden's Ave." description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Garden's Ave." description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" /> */}
            </Grid>
            {/* <Quotation /> */}
        </>
    )
}

export default VrPage
