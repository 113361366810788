import { Box } from '@mui/material'
import DesignPortfolio from '../components/DesignPortfolio';
import Quotation from '../components/QuotationComp';
import { intData } from '../shared/DesignData';
import { Routes, Route } from 'react-router-dom';


import GardenAve from './Design/GardenAve'
import Eastown from './Design/Eastown'


const Design = () => {



    return (
        <Routes>
            <Route index element={
                <Box>
                    <DesignPortfolio data={intData} />
                    <Quotation />
                </Box>
            } />
            <Route path="garden-ave" element={<GardenAve />} />
            <Route path="eastown" element={<Eastown />} />
        </Routes>
    )
}

export default Design
