import { Grid, Typography, Box } from "@mui/material"

const Intro = (props) => {
    const { title, alt, description } = props
    return (
        <Box maxWidth={1200} marginX="auto" paddingY={10} backgroundColor="primary.light">
            <Grid container display="flex" justifyContent="center" alignItems="center">
                <Grid md={6}>
                    <Typography color="dark.main" variant="h5" component="h2" >{title}</Typography>
                    <Typography color="primary.dark" variant="subtitle2" component="body1">{alt}</Typography>
                </Grid>
                <Grid md={6}>
                    <Typography>
                        {description}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Intro