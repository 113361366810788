import { Box } from '@mui/material'
import styled from 'styled-components'

const BannerImg = styled.img`
    width: 100vw;
    height: 700px;
    object-fit: cover;
`

const Banner = (props) => {
    return (
        <Box p={0} display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light">
            <BannerImg src={props.img} alt={props.alt} />
        </Box>
    )
}

export default Banner