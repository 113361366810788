import { ImgItem, BannerImg } from './AboutElements'
import ImgMin1 from '../../images/Minimal-1.jpg'
import ImgMin2 from '../../images/Minimal-2.jpg'
import ImgMin3 from '../../images/Minimal-3.jpg'
import ImgMin4 from '../../images/Minimal-4.jpg'
import buildingImage from '../../images/model.png'
import easttownImage from '../../images/Eastown/02.jpg'

import { Box, Grid, Typography } from '@mui/material'

const SectionAbout = () => {
    return (
        <>
            <Box paddingBottom={20} paddingTop={40} backgroundColor="primary.light" display="flex" justifyContent="center" alignItems="center">
                <Grid container maxWidth="800px" display="flex" justifyContent="center" alignItems="center">
                    <Grid md={3} display="flex" justifyContent="center" alignItems="center">
                        <ImgItem src={ImgMin1}></ImgItem>
                    </Grid>
                    <Grid md={3} display="flex" justifyContent="center" alignItems="center">
                        <ImgItem src={ImgMin2}></ImgItem>
                    </Grid>
                    <Grid md={3} display="flex" justifyContent="center" alignItems="center">
                        <ImgItem src={ImgMin3}></ImgItem>
                    </Grid>
                    <Grid md={3} display="flex" justifyContent="center" alignItems="center">
                        <ImgItem src={ImgMin4}></ImgItem>
                    </Grid>
                </Grid>
            </Box>
            <Box paddingY={5} display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light">
                <Box maxWidth={1200}>
                    <Typography marginY={10} variant="h5" component="h5" align="center">
                        Our architecture is minimal, smooth & human-centered. Always seeking the full satisfaction of those who will inhabit it. We take into consideration every single detail to create a unique human experience. We are passionate about our work.
                    </Typography>
                </Box>
            </Box>

            <Box paddingY={20} display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light">
                <BannerImg src={easttownImage} alt="living Room"></BannerImg>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <Grid container>
                        <Grid md="6" padding={3}>
                            <Typography marginY={10} variant="h5" component="h5" align="center">
                                We provide various options for your project to be used in WebGL & VR technologies. Our skilled 3D artists & developers will work hardly to establish a creative platform for you.
                            </Typography>
                        </Grid>
                        <Grid md="6" padding={3}>
                            <img src={buildingImage} alt="building" width="100%" height="100%"></img>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </>
    )
}

export default SectionAbout
