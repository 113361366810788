import React from 'react'
import { Box, Grid } from '@mui/material'
import { ServiceItem } from './ServiceGridElements'
import ServiceImg1 from '../../images/Grid-Design.png'
import ServiceImg3 from '../../images/soInteract.jpg'
import ServiceImg4 from '../../images/gardenAve/plan.png'

import VRCover from '../../images/vrCover.jpg'




function ServiceGrid() {
    return (
        <Box backgroundColor="primary.light" paddingTop={10} paddingBottom={30}>
            <Grid container maxWidth={1200} display="flex" alignItems="center" justifyContent="center" marginX="auto">
                <ServiceItem path="./design" title="Design" img={ServiceImg1} />
                <ServiceItem path="./vr" title="VR" img={VRCover} />
                <ServiceItem path="./webapp" title="Web Apps" img={ServiceImg3} />
                <ServiceItem path="./other-services" title="Other Services" img={ServiceImg4} />
            </Grid>
        </Box>
    )
}

export default ServiceGrid
