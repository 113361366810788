import React from 'react';
import {
    SidebarContainer,
    SidebarLink,
    SidebarProjects,
    FeaturedProjectsTitle,
    SidebarStatic,
} from './SidebarEements';
import { Avatar, Box, Grid, Link, Modal, Typography } from '@mui/material';
import TeamMember from './TeamMember';
import SherifZayed from '../../images/sherifzayed.jpg'
import LinaM from '../../images/linam.jpg'
import FeaturedProject from './FeaturedProject';

import gardenAveImage from '../../images/gardenAve/02.jpg';
import sodicEastImage from '../../images/Back01.jpg'
import riverImage from '../../images/river.jpg'
import soInteract from '../../images/soInteract.jpg'
import { useNavigate } from 'react-router-dom';




const Sidebar = ({ isOpen, toggle }) => {

    const navigate = useNavigate()
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Grid container height={"100%"}>
                <Grid sm={4} md={3} display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
                    <Box alignSelf={"start"} width={"100%"}>
                        <SidebarLink to="/design" onClick={toggle}>
                            <Typography variant="subtitle1" component="h5" color="dark.main" sx={{
                                paddingLeft: '20px',
                                textDecoration: 'none',
                                listStyle: 'none',
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer'
                            }}>Design</Typography>
                        </SidebarLink>
                        <SidebarLink to="/vr" onClick={toggle}>
                            <Typography variant="subtitle1" component="h5" color="dark.main" sx={{
                                paddingLeft: '20px',
                                textDecoration: 'none',
                                listStyle: 'none',
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer'
                            }}>VR</Typography>
                        </SidebarLink>
                        <SidebarLink to="/other-services" onClick={toggle}>
                            <Typography variant="subtitle1" component="h5" color="dark.main" sx={{
                                paddingLeft: '20px',
                                textDecoration: 'none',
                                listStyle: 'none',
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer'
                            }}>Other Services</Typography>
                        </SidebarLink>
                        <SidebarLink to="/webapp" onClick={toggle}>
                            <Typography variant="subtitle1" component="h5" color="dark.main" sx={{
                                paddingLeft: '20px',
                                textDecoration: 'none',
                                listStyle: 'none',
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer'
                            }}>Web Apps</Typography>
                        </SidebarLink>
                        <SidebarLink to="/projects" onClick={toggle}>
                            <Typography variant="subtitle1" component="h5" color="dark.main" sx={{
                                paddingLeft: '20px',
                                textDecoration: 'none',
                                listStyle: 'none',
                                transition: 'all 0.2s ease-in-out',
                                cursor: 'pointer'
                            }}>Projects</Typography>
                        </SidebarLink>
                    </Box>
                    <Box width={'100%'}>
                        <Typography align={"center"} variant="subtitle1" component="h5" color={'dark.main'}>
                            Our Team
                        </Typography>
                        <Box width="100%" margin={0} p={0}>
                            <Grid container >
                                <TeamMember title="Sherif Za." job="Desginer/Dev." image={SherifZayed} path="https://www.linkedin.com/in/sherif-zayed-b528661b3/" />
                                <TeamMember title="Lina Elnag." job="UI/UX Dev." image={LinaM} path="https://www.linkedin.com/in/lina-elnaggar/" />
                                <TeamMember title="Dalia Ka." job="Designer" image={null} path="" />
                            </Grid>
                        </Box>
                    </Box>
                </Grid>

                <Grid sm={8} md={9} display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
                    <Typography variant="h5" component="h5" color='dark.main'>Featured projects</Typography>
                    <Grid container padding={3}>
                        <FeaturedProject title="Garden's Ave." description="Garden's Ave is a full rennovation project for a residential property." img={gardenAveImage} path="/design/garden-ave/" />
                        <FeaturedProject title="Eastown" description="Eastown is our latest interior design project for a three-bedroom apartment" img={sodicEastImage} path="/design/eastown/" />
                        <FeaturedProject title="River JS" description="Large-scaled gated community project available in Web3 technology" img={riverImage} path="/webapp/river/" />
                        <FeaturedProject title="SO Interact" description="An Interactive building to demonistrate available apartments for real estate agents" img={soInteract} path="/webapp/so-interact/" />
                    </Grid>
                    <Typography variant="caption" component="h5" color='dark.main'>Copyrights to bricksvisuals 2022</Typography>
                </Grid>
            </Grid>
        </SidebarContainer>
    )
}

export default Sidebar
