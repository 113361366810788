import React  from 'react'
import { ProjectCont, DesignPicsDiv, DesignPics } from './ProjectsElements'

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



// import {data} from '../../shared/DesignData'
import {Projectdata as data} from '../../shared/ProjectsData'
import { blueGrey } from '@mui/material/colors';
import { Box } from '@mui/system';


const ProjectsCards = () => {

    const [alignment, setAlignment] = React.useState('web');

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);
    };


    return (
        <>  
        {alignment}
        <Box>

                <ToggleButtonGroup
                    color= "primary"
                    value={alignment}
                    exclusive
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    onChange={handleChange}
                    >
                    <ToggleButton sx={{ mt: 3}} value="all">All</ToggleButton>
                    <ToggleButton sx={{ mt: 3}} value="design">Design</ToggleButton>
                    <ToggleButton sx={{ mt: 3}} value="VR">Android</ToggleButton>
                    <ToggleButton sx={{ mt: 3}} value="Front-End Development">Front-End Development</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <ProjectCont>
                {data.map((item, index) => {
                    return(
                        <DesignPicsDiv key={index}>
                            <DesignPics src={item.imgSrc}/>
                            {item.project}
                            {item.Cat}
                        </DesignPicsDiv>
                    )
                })}
            </ProjectCont>   
        </>
    )
}

export default ProjectsCards
