import styled from 'styled-components';

export const ImgItem = styled.img`
    width: 187px;
    height: 421px;
    margin: 10px;
`

export const BannerImg = styled.img`
object-fit: cover;
width: 100%;
max-height: 500px;
object-position: bottom;
`
