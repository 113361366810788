import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: ${({ isOpen }) => (isOpen ? '999' : '-10')};
    padding-top: 120px;
    padding-bottom: 10px;
    width: 100vw;
    height: 100vh;
    background: #F0F0F0;
    top: 0;
    left: 0;
    transition: all 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    overflow-y: auto;

`;

export const LogoBlack = styled.img`
    max-width: 50px;
    position: fixed;
    top: 20px;
    left: 50px;
  
`

export const SidebarStatic = styled.div`
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #464646;
`

export const TeamCard = styled.img`
    border-radius: 200px;
    width: 100%;
    height: 100%;

`

export const TeamCardTitle = styled.div`
    transition: .5s ease;
    opacity: ${({ hover }) => (hover ? '1' : '0')};
    position: absolute;
    top: 55px;
    left: 60px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
`

export const SidebarProjects = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const FeaturedProjectsTitle = styled.h3`
    color: #464646;

`




export const SidebarLink = styled(LinkR)`

    text-decoration: none;
    list-style: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        color: #b2b2b2;
        transition: all 0.2 ease-in-out;
    }
`
export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
`
export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2 ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #0101606;
    }
`

