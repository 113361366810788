import img2 from '../images/Render02.jpg'
import img1 from '../images/Render02.jpg'
import img4 from '../images/Render114.jpg'



// Garden Ave Pictures
import garden1 from '../images/gardenAve/01.jpg'
import garden2 from '../images/gardenAve/02.jpg'
import garden3 from '../images/gardenAve/03.jpg'
import garden4 from '../images/gardenAve/04.jpg'
import garden5 from '../images/gardenAve/05.jpg'
import garden6 from '../images/gardenAve/06.jpg'
import garden7 from '../images/gardenAve/07.jpg'
import garden8 from '../images/gardenAve/08.jpg'
import garden9 from '../images/gardenAve/09.jpg'
import garden10 from '../images/gardenAve/10.jpg'


// Eastown

import eastTown1 from '../images/Eastown/01.jpg'
import eastTown2 from '../images/Eastown/02.jpg'
import eastTown3 from '../images/Eastown/03.jpg'
import eastTown7 from '../images/Eastown/07.jpg'
import eastTown8 from '../images/Eastown/08.jpg'
import eastTown9 from '../images/Eastown/09.jpg'
import eastTown10 from '../images/Eastown/10.jpg'
import eastTown11 from '../images/Eastown/13.jpg'
import eastTown12 from '../images/Eastown/20.jpg'





export let intData = [
    {
        id: 1,
        imgSrc: img2,
        project: 'ss',
        text: "Sodic Interior"
    },
    {
        id: 3,
        imgSrc: img4,
        project: 'ss',
        text: "4Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. In vitae turpis massa sed elementum tempus egestas sed sed. Pulvinar mattis nunc sed blandit libero volutpat sed cras. At elementum eu facilisis sed odio."
    },
    {
        id: 6,
        imgSrc: garden1,
        project: 'green-ave',
        text: "Entrance Gate of Garden's Ave project"
    },
    {
        id: 7,
        imgSrc: garden2,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 8,
        imgSrc: garden3,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 9,
        imgSrc: garden4,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 10,
        imgSrc: garden5,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 11,
        imgSrc: garden6,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 12,
        imgSrc: garden7,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 13,
        imgSrc: garden8,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 14,
        imgSrc: garden9,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 15,
        imgSrc: garden10,
        project: 'green-ave',
        text: "Exterior view of Garden's Ave project"
    },
    {
        id: 20,
        imgSrc: eastTown1,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 21,
        imgSrc: eastTown2,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 22,
        imgSrc: eastTown3,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 26,
        imgSrc: eastTown7,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 27,
        imgSrc: eastTown8,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 28,
        imgSrc: eastTown9,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 29,
        imgSrc: eastTown10,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 30,
        imgSrc: eastTown11,
        project: 'eastown',
        text: "Interior View"
    },
    {
        id: 31,
        imgSrc: eastTown12,
        project: 'eastown',
        text: "Interior View"
    }
]
