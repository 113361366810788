import emailjs from '@emailjs/browser';

const SendQuote = async (data) => {

    const publicKey = "kATVuDdFqEFbhs74w";
    const templateID = "template_gboll2d";
    const templateID2 = "template_h25o5en";
    const serviceID = "service_g7fz8js";

    // service ID
    const resp = emailjs.send(serviceID, (data.type) ? templateID : templateID2, data, publicKey, data['g-recaptcha-response'])
        .then((result) => {
            console.log(result.text);
            return result
        }, (error) => {
            console.log(error.text);
            return error
        });
    return await resp
};



export default SendQuote;