import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";



const SliderComp = (props) => {

    const ImageItem = styled.img`
    object-fit: cover;
    max-height: 450px;
    object-position: bottom;
    margin: 20px;
    max-width: 700px;
    `


    const settings = {
        className: "center",
        centerPadding: "0px",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        swipeToSlide: true,
        focusOnSelect: true,

        edgeFriction: 0.15,
        dots: true,
        variableWidth: true,
    };


    return (
        <Box backgroundColor="primary.light" sx={{cursor: 'pointer'}} py={20}>
            <Slider {...settings} >
                {props.images.map((image, i) => (
                    <ImageItem key={i}
                        id={image}
                        src={image}
                        alt="image1" />
                ))}
            </Slider>
        </Box>
    )
}

export default SliderComp