import { useState } from 'react'
import SectionAbout from '../components/AboutSection'
import HeroSection from '../components/HeroSection'
import Quotation from '../components/QuotationComp'
import ServiceGrid from '../components/ServiceGrid'

const Home = () => {
    const [loading, setLoading] = useState(true)
    return (
        <>
            <HeroSection loading={loading} setLoading={setLoading}></HeroSection>
            <SectionAbout></SectionAbout>
            <ServiceGrid></ServiceGrid>
            <Quotation></Quotation>
        </>
    )
}

export default Home
