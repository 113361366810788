import img1 from '../images/Back02.jpg'
import img2 from '../images/Render02.jpg'
import img4 from '../images/Render114.jpg'
import img5 from '../images/Grid-VR.png'




export let Projectdata = [
    {
        id: 0,
        imgSrc: img1,
        project: 'Hassan',
        Cat: 'design',
        text: "1Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. In vitae turpis massa sed elementum tempus egestas sed sed. Pulvinar mattis nunc sed blandit libero volutpat sed cras. At elementum eu facilisis sed odio."
    },
    {
        id: 1,
        imgSrc: img2,
        project: 'Gate',
        Cat: 'vr',
        text: "2Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. In vitae turpis massa sed elementum tempus egestas sed sed. Pulvinar mattis nunc sed blandit libero volutpat sed cras. At elementum eu facilisis sed odio."
    },
    {
        id: 3,
        imgSrc: img4,
        project: 'Hassan',
        Cat: 'dev',
        text: "4Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. In vitae turpis massa sed elementum tempus egestas sed sed. Pulvinar mattis nunc sed blandit libero volutpat sed cras. At elementum eu facilisis sed odio."
    },
    {
        id: 4,
        imgSrc: img5,
        project: 'Interior',
        Cat: 'design',
        text: "5Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. In vitae turpis massa sed elementum tempus egestas sed sed. Pulvinar mattis nunc sed blandit libero volutpat sed cras. At elementum eu facilisis sed odio."
    },

]