import styled from "styled-components";
import Tooltip from '@mui/material/Tooltip';


export const DesignPics = styled.img`
    width: 100%;
`

export const Modal = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.9);
    transition: opacity 0.5s ease, visibilty 0.5s ease, transform 0.3s ease-in-out;
    visibility: ${
        (props) => (props.isOpen ? 'visible' : 'hidden')
    };
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    transform: scale(${(props) => (props.isOpen ? 1 : 0)});
    overflow: hidden;
    z-index: 2000;
`



export const ModalImg = styled.img`
    width: auto;
    max-width: calc(100% - 20px);
    height: 100%;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
        height: auto;
    }
`

export const ModalIcons = styled.div`
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin: 10px;
    cursor: pointer;

    .ModalIcons  {
        margin: 10px;
    }
`

export const RightArrow = styled.div`
    color: #fff;
    position: fixed;
    top: 50%;
    left: 0;
    margin: 10px;
    cursor: pointer;
    transform: scale(2);
`

export const LeftArrow = styled.div`
    color: #fff;
    position: fixed;
    top: 50%;
    right: 0;
    margin: 10px;
    cursor: pointer;
    transform: scale(2);
`





export const CustomWidthTooltip = styled(props => (
    <Tooltip classes={{ popper: props.className }} {...props} />
  ))`
    & .MuiTooltip-tooltip {
        font-size: 14px;
      width: 400px;
    }
  `;