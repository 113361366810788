import { useState } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { VideoPlay } from './HeroElements'
import video from '../../video/Intro.mp4'


const HeroSection = (props) => {

    const { loading, setLoading } = props;

    return (
        <Box position="relative" width="100vw" padding={0} margin={0} sx={{ height: `${window.innerHeight - 150}px` }}>
            {/* {loading ?
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                : null} */}
            {loading ?
                <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                : null
            }

            <Box sx={{ display: loading ? 'none' : 'block', height: '100%', width: '100%' }}>
                <VideoPlay autoPlay={true} loop muted onLoadedData={() => setLoading(false)} src={video} />
            </Box>
            <Box position="absolute" right={100} bottom={20}>
                <Typography variant='h5' component="h5" color="primary.light">
                    {loading ? "loading" : "See it, feel it, believe in it."}
                </Typography>
            </Box>
        </Box>
    )
}

export default HeroSection
