import React from 'react';
// import { FaBars } from 'react-icons/fa';
import { Logo } from './NavbarElements';
// import LogoImage from '../../images/Logo.png';
import LogoBlackimg from '../../images/LogoBlack.png';
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';



import { AppBar, Typography, useScrollTrigger, Grid, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';



const Navbar = (props) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 80,
    });

    const navigate = useNavigate();
    const location = useLocation();

    const currentLocation = () => {
        const loc = location.pathname.split('/')[1]
        if (loc.length > 0) return loc.charAt(0).toUpperCase() + loc.slice(1)
        return "Imagination is the beginning of creation."
    }

    return (
        <>
            <AppBar sx={{ backgroundColor: 'rgba(255, 0, 0, 0)' }} elevation={0}>
                <Grid container padding={5} height='100px'>
                    <Grid md={4} display="flex" justifyContent="start" alignItems="center">
                        <Box sx={{ cursor: "pointer" }}>
                            <Logo src={LogoBlackimg} onClick={() => navigate('./')}></Logo>
                        </Box>
                    </Grid>
                    <Grid md={4} display="flex" justifyContent="center" alignItems="center">
                        <Typography cursor="none" color={'dark.main'} variant="h5" component="h1" sx={{ cursor: 'none', transition: '0.5s all ease', opacity: !trigger && !props.isOpen ? 1 : 0 }}>{currentLocation()}</Typography>
                    </Grid>
                    <Grid  md={4} display="flex" justifyContent="end" alignItems="center">
                        {props.isOpen ? (
                            <CloseIcon cursor="pointer" color={'dark'} onClick={props.toggle} />
                        ) : (
                            <MenuIcon cursor="pointer" color={'dark'} onClick={props.toggle} />
                        )}
                    </Grid>
                </Grid>
            </AppBar>
        </>
    )
}

export default Navbar;
