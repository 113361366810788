import { useState } from 'react';
import {
    TeamCard,
    TeamCardTitle
} from './SidebarEements';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';

const TeamMember = (props) => {

    const [hover, setHover] = useState(false)

    return (
        <Grid md={4} position="relative"
            sx={{}}
            display='flex' justifyContent="center" alignItems="center"
            flexDirection={'column'}
        >
            <Box sx={{ maxWidth: '80px', height: '80px', cursor: 'pointer' }}
                onPointerEnter={(e) => {
                    e.stopPropagation();
                    setHover(true);
                }}
                onPointerLeave={(e) => {
                    e.stopPropagation();
                    setHover(false);
                }}
            >
                <TeamCard src={props.image ? props.image : <Avatar>D</Avatar>} />
            </Box>
            <TeamCardTitle hover={hover}>
                <Typography variant="h5" component="h5" color="dark.main">
                    {(hover) ?
                        <Link to={props.path}>
                            <LinkedInIcon color="primary"
                                onPointerEnter={(e) => setHover(true)}
                            />
                        </Link>
                        : null}
                </Typography>
            </TeamCardTitle>
            <Typography variant="subtitle1" component="h5" color="dark.main">
                {props.title}
            </Typography>
            <Typography variant='caption' component="h5" color="dark.main">
                {props.job}
            </Typography>
        </Grid>
    )
}

export default TeamMember