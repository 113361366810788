import { useState } from 'react';
import { Button, Card, Grid, Box, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ServiceImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%
`



export const ServiceItem = (props) => {

    const [hover, setHover] = useState(false)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const { path, title, img } = props


    return (
        <Grid md={6} sm={12} padding={1} sx={{ borderRadius: 0 }} onClick={() => navigate(path)} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)} cursor="pointer">
            <Card display="flex" position="relative">
                <Box position="relative" sx={{ cursor: 'pointer' }}>
                    {loading ? (
                        <Box display="absoulte" top={0} left={0} height="400px" zIndex={2} sx={{ opacity: hover ? 0.9 : 1 }}>
                            <Skeleton variant="rectangular" width={"100%"} height={400} />
                        </Box>
                    ) : null}
                    <Box display="absoulte" top={0} left={0} height="400px" sx={{ opacity: hover ? 0.9 : 1, display: loading ? 'none' : 'block' }}>
                        <ServiceImg alt="title" src={img} onLoad={() => setLoading(false)} />
                    </Box>
                    <Box position="absolute" top={0} left={0} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                        <Button variant={hover ? 'outlined' : 'contained'} size="small" color="primary" sx={{ borderRadius: 0, padding: '10px 20px' }}>{loading ? "is loading" : title}</Button>
                    </Box>
                </Box>
            </Card>
        </Grid>
    )
}