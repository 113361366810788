import React from 'react'
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Sent = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" sx={{ opacity: 1 }} paddingY={10} position="absolute" width="100%" height="100%" zIndex={2} flexDirection="column">
            <CheckCircleIcon color="success" sx={{ width: "100px", height: "100px" }} />
            <Typography variant='body1' component="h5">Email sent successfully.</Typography>
            <Typography variant="subtitle1" component="h5">Our Team will contact ASAP</Typography>
        </Box>
    )
}

export default Sent