import { Box, Typography } from '@mui/material'
import styled from "styled-components";


import Intro from './Intro';
import Banner from './Banner';
import Quotation from '../../components/QuotationComp'
import Slider from './Slider'


import image1 from '../../images/Eastown/01.jpg'
import image2 from '../../images/Eastown/02.jpg'
import image3 from '../../images/Eastown/03.jpg'
import image7 from '../../images/Eastown/07.jpg'
import image8 from '../../images/Eastown/08.jpg'
import image9 from '../../images/Eastown/09.jpg'
import image10 from '../../images/Eastown/10.jpg'
import image11 from '../../images/Eastown/11.jpg'
import image13 from '../../images/Eastown/13.jpg'
import image20 from '../../images/Eastown/20.jpg'


const Eastown = () => {

    const ImageItem = styled.img`
    object-fit: cover;
    height: 100vh;
    object-position: bottom;
    margin: 20px;
    max-width: 500px;
`

    return (
        <>
            <Banner img={image1} alt={"living Room"} />

            <Box backgroundColor="primary.light">
                <Intro title={"Eastown"} alt={"Cairo, Egypt 2022"} description={"A mix of Minimal Architecture and Industrial for an apartment in Sodic Eastown in Egypt. "}></Intro>
            </Box>


            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <ImageItem src={image2} alt="plan" />
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <Typography variant="h5" component="h5" align="center">
                        Industrial design and home decor—which features metal and open space—may appear cold to some, but the style actually incorporates many elements of Scandinavian’s cozy hygge interior design. Hence, Elements were choosen carefully in a minimal aspects
                    </Typography>
                </Box>
            </Box>

            {/* <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <img src={planImage} alt="plan" width="100%" height="100%"></img>
                </Box>
            </Box> */}

            <Slider images={[image1, image2, image3, image7, image8, image9, image10, image11, image13, image20]} />

            <Quotation />
        </>
    )
}

export default Eastown