import React from 'react'
import { Banner, BannerTxt } from './HeroBannerElements'

const HeroBanner = (props) => {
    return (
        <>

        </>
    )
}

export default HeroBanner
