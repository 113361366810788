
import ProjectCard from '../components/ProjectCards'
import Quotation from '../components/QuotationComp'
import ServiceImg1 from '../images/Grid-Design.png'
import { Grid } from '@mui/material'


const WebAppsPage = () => {
    return (
        <>
            <Grid container padding={3}>
                <ProjectCard title="Airbnb Clone" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="River" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Interactive Apt" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
                <ProjectCard title="Dashboard" description="Garden's Ave is a very nice project and this is a short description of the project." img={ServiceImg1} path="" />
            </Grid>
            <Quotation />
        </>
    )
}

export default WebAppsPage
