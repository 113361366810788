import { Box, Typography } from '@mui/material'
import styled from "styled-components";


import planImage from '../../images/gardenAve/plan.png'
import easttownImage from '../../images/gardenAve/02.jpg'

import image1 from '../../images/gardenAve/01.jpg'
import image2 from '../../images/gardenAve/02.jpg'
import image3 from '../../images/gardenAve/03.jpg'
import image4 from '../../images/gardenAve/04.jpg'
import image5 from '../../images/gardenAve/05.jpg'
import image6 from '../../images/gardenAve/06.jpg'
import image7 from '../../images/gardenAve/07.jpg'
import image8 from '../../images/gardenAve/08.jpg'
import image9 from '../../images/gardenAve/09.jpg'
import image10 from '../../images/gardenAve/10.jpg'

import Intro from './Intro'
import Banner from './Banner'
import Quotation from '../../components/QuotationComp'
import Slider from './Slider'




const GardenAve = () => {

    const ImageItem = styled.img`
    object-fit: cover;
    height: 100vh;
    object-position: bottom;
    margin: 20px;
    max-width: 500px;
`


    return (
        <>
            <Banner img={easttownImage} alt={"living Room"} />

            <Box backgroundColor="primary.light">
                <Intro title={"Green Avenue"} alt={"Alexandria, Egypt 2021-2022"} description={"Inspired by the local natural environment, and the movement of the sun throughout the day, Garden's Avenue architecture exemplifies timeless quality. The building presents a refined expression of form paired with an authentic and natural material palette that imbues the overall appearance with texture and warmth."}></Intro>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <ImageItem src={image4} alt="plan" />
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <Typography variant="h5" component="h5" align="center">
                        Diffrentiating between private and public landscape areas in relation to building circulation was the biggest challenge, Garden's Avenue was designed carefully to serve a great family home.
                    </Typography>
                </Box>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="primary.light" paddingY={20}>
                <Box maxWidth={1200} >
                    <img src={planImage} alt="plan" width="100%" height="100%"></img>
                </Box>
            </Box>

            <Slider images={[image1, image2, image3, image4, image5, image6, image7, image8, image9, image10]} />

            <Quotation />
        </>
    )
}

export default GardenAve