import React, { useState } from 'react'
import { DesignPics, Modal, ModalImg, ModalIcons, RightArrow, LeftArrow, CustomWidthTooltip } from './DesignPortElements'
import { useNavigate } from "react-router-dom";

import { Box, ImageList, ImageListItem, Tooltip, ClickAwayListener } from '@mui/material';


import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';



const DesignPortfolio = (props) => {

    const data = props.data;

    const navigate = useNavigate();

    const [modal, setModal] = useState(false)
    const [tempIndex, setTempIndex] = useState(0)
    const [tempData, setTempData] = useState({
        id: 0,
        imgSrc: '',
        project: '',
        text: ''
    })


    const setIndex = (index) => {
        setTempIndex(index)
        setTempData(data[index])
        setModal(true)
    }


    const nextImage = () => {
        setIndex(tempIndex + 1)
    }

    const prevImage = () => {
        setIndex(tempIndex - 1)
    }


    const [tipOpen, setTipOpen] = useState(false);

    const handleTooltipClose = () => {
        setTipOpen(false);
        setTempIndex(null)
    }

    const handleTooltipOpen = () => {
        setTipOpen(!tipOpen);
    }

    const gotoProject = () => {
        if (tempIndex === 0 || tempIndex) {
            navigate(`../${tempData.project}`)
        }
    }


    return (
        <>
            <Modal scroll="no" isOpen={modal}>
                <ModalImg src={tempData.imgSrc} />
                <ModalIcons>
                    <Tooltip placement='left' title="Close">
                        <CloseIcon className="ModalIcons" onClick={() => setModal(false)} />
                    </Tooltip>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <CustomWidthTooltip
                            className="ModalIcons"
                            onClick={handleTooltipOpen}
                            placement='left'
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={tipOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={tempData.text}>
                            {tipOpen ? <InfoIcon /> : <InfoOutlinedIcon />}
                        </CustomWidthTooltip>
                    </ClickAwayListener>
                    <Tooltip placement='left' title="Full Project" sx={9000}>
                        <LaunchIcon className="ModalIcons" onClick={() => gotoProject()} />
                    </Tooltip>
                </ModalIcons>
                {(tempIndex + 1 < data.length ? (
                    <LeftArrow onClick={nextImage}>
                        <Tooltip title="Next">
                            <ChevronRightIcon className="ModalIcons" />
                        </Tooltip>
                    </LeftArrow>
                ) : '')}

                {(tempIndex > 0 ? (
                    <RightArrow onClick={prevImage}>
                        <Tooltip title="Previous">
                            <ChevronLeftIcon className="ModalIcons" />
                        </Tooltip>
                    </RightArrow>
                ) : '')}
            </Modal>


            <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll', backgroundColor: "primary.light", padding: '40px 20px' }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                    {data.map((item, index) => (
                        <Box key={index} sx={{
                            transition: 'all 350ms ease',
                            cursor: 'pointer',
                            "&:hover" : {
                                filter: 'opacity(0.8)'
                            }
                        }}>
                            <DesignPics
                                onClick={() => setIndex(index)}
                                src={`${item.imgSrc}?w=100%&fit=contain&auto=format`}
                                srcSet={`${item.imgSrc}?w=100%&fit=contain&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                            />
                        </Box>
                    ))}
                </ImageList>
            </Box>
        </>
    )
}

export default DesignPortfolio;





